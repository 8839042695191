import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import "../sass/main.scss"
const JobDescription = ({pageContext}) => {
    return (
      <>
        <Header
          title={"East Side Enterprises"}
          description={"East Side Enterprises"}
          hidden={false}
          showBackButton={true}
          bodyClasses={"page-index"}
        />
        <main id={"main"} className={"job-description"}>
          <section className={"container pb5"}>
            <h1 className={"mb4"}>{pageContext.title}</h1>
            <div className={"c"}>
              <div className={"r fs22"}>
                <b>Position Type:</b>&nbsp; {pageContext.status}
              </div>
              <div className={"r fs22"}>
                <b>Career Category: </b>&nbsp; {pageContext.jobCategory}
              </div>
              <div className={"r fs22"}>
                <b>Shift Type: </b>&nbsp; {pageContext.shiftType}
              </div>
              <div className={"r fs22"}>
                <b>Address: </b>&nbsp; {pageContext.address}
              </div>
            </div>
            <div className={"my4"}>
              <a
                target={"_blank"}
                className={""}
                rel={"nofollow"}
                href={pageContext.formUrl}
              >
                <button className={"button"}>Apply Now</button>
              </a>
            </div>
            <div
              className="job-description-content"
              style={{}}
              dangerouslySetInnerHTML={{ __html: pageContext.description }}
            />
            <div className={"py5"}>
              <a target={"_blank"} rel={"nofollow"} href={pageContext.formUrl}>
                <button className={"button"}>Apply Now</button>
              </a>
            </div>
          </section>
        </main>
        <Footer />
      </>
    );}

export default JobDescription;